import { REQUESTER_TYPES } from '@/utils/constants/permissions';

// Least specific -> more specific
export const PERMISSION_SEQUENCE_TOP_TO_BOTTOM = [
  REQUESTER_TYPES.USER,
  REQUESTER_TYPES.TEAM,
  REQUESTER_TYPES.COMPANY,
];

if (Object.freeze) {
  Object.freeze(PERMISSION_SEQUENCE_TOP_TO_BOTTOM);
}

export const PERMISSION_LEVELS = {
  READ: 'read',
  EDIT: 'edit',
  MANAGE: 'manage',
};

if (Object.freeze) {
  Object.freeze(PERMISSION_LEVELS);
}

export const getLabelForPermissionLevel = (level) => {
  switch (level) {
    case PERMISSION_LEVELS.EDIT: {
      return 'Edit';
    }
    case PERMISSION_LEVELS.MANAGE: {
      return 'Manage';
    }
    case PERMISSION_LEVELS.READ:
    default: {
      return 'Read';
    }
  }
};

export const getDescriptionForPermissionLevel = (level) => {
  switch (level) {
    case PERMISSION_LEVELS.READ:
    default: {
      return 'Can view and comment on space pages';
    }
    case PERMISSION_LEVELS.EDIT: {
      return 'Can create, edit and delete space pages, make inline comments';
    }
    case PERMISSION_LEVELS.MANAGE: {
      return 'Can create, edit and delete space pages, make inline comments and manage space members';
    }
  }
};

export const getDecoratedPermissionLevels = () => Object.values(PERMISSION_LEVELS).map((level) => ({
  value: level,
  label: getLabelForPermissionLevel(level),
  desc: getDescriptionForPermissionLevel(level),
}));

export { REQUESTER_TYPES } from '@/utils/constants/permissions';

export default {
  REQUESTER_TYPES,
  PERMISSION_LEVELS,
  PERMISSION_SEQUENCE_TOP_TO_BOTTOM,
};
