import { render, staticRenderFns } from "./FormLabel.vue?vue&type=template&id=42de2555&scoped=true"
import script from "./FormLabel.js?vue&type=script&lang=js&external"
export * from "./FormLabel.js?vue&type=script&lang=js&external"
import style0 from "./FormLabel.scss?vue&type=style&index=0&id=42de2555&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42de2555",
  null
  
)

export default component.exports