import { render, staticRenderFns } from "./PresentCollaboratorsList.vue?vue&type=template&id=12a964f8&scoped=true"
import script from "./PresentCollaboratorsList.js?vue&type=script&lang=js&external"
export * from "./PresentCollaboratorsList.js?vue&type=script&lang=js&external"
import style0 from "./PresentCollaboratorsList.scss?vue&type=style&index=0&id=12a964f8&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a964f8",
  null
  
)

export default component.exports