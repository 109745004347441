var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-popover',{staticClass:"s-toolbar-button-list",attrs:{"position-fixed":true,"is-arrow-enabled":true,"align-arrow-with-reference":true,"disable-toggle":_vm.disabled,"placement":_vm.popperPlacement,"offset":[0, 12]},scopedSlots:_vm._u([{key:"button",fn:function(ref){
var isOpen = ref.isOpen;
return [_c('spaces-editor-toolbar-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"s-toolbar-button-list__button",attrs:{"disabled":_vm.disabled,"is-active":_vm.showParentButtonAsActive || isOpen,"variant":_vm.variant}},[_c('span',{staticClass:"s-toolbar-button-list__button-content"},[_vm._t("button"),_vm._v(" "),(_vm.includeChevronDown)?_c('fa-icon',{staticClass:"overflow-icon",attrs:{"icon":['far', 'chevron-down']}}):_vm._e()],2)])]}},{key:"content",fn:function(ref){
var close = ref.close;
return [_vm._t("children",function(){return [_c('ul',{staticClass:"s-toolbar-button-list__list",class:{
          's-toolbar-button-list__list--horizontal': _vm.horizontalChildren,
        }},_vm._l((_vm.types),function(type,index){return _c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(type.tooltip || ''),expression:"type.tooltip || ''"}],key:index,staticClass:"toolbar-button-list__list-item",class:[
            { 'toolbar-button-list__list-item--is-active': _vm.activeTypes[index] },
            { 'toolbar-button-list__list-item--divider': type.showDivider },
            type.class ? type.class : '' ],on:{"click":function($event){return _vm.onListItemClick(type, close)}}},[_vm._t("child",function(){return [_c('div',[_c('p',[_vm._v(" "+_vm._s(type.title))])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(type.shortcut && _vm.shouldShowShortcutKey()),expression:"type.shortcut && shouldShowShortcutKey()"}],staticClass:"shortcut-key"},[_c('span',{staticClass:"keyboard-shortcut-item__key"},[_vm._v(_vm._s(type.shortcut))])])]},{"child":type,"isActive":_vm.activeTypes[index]})],2)}),0)]},{"close":close})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }