import store from '@/store';

import { ROUTES, ROUTE_NAMES } from './routes';
import logout from './utils/logout';

export const DEFAULT_HOME_ROUTE_NAME = ROUTE_NAMES.STARRED_SPACES;
export const DEFAULT_HOME_ROUTE = ROUTES[DEFAULT_HOME_ROUTE_NAME];

export default [
  {
    path: '/',
    redirect: ROUTES[ROUTE_NAMES.HOME].path,
    component: () => import(/* webpackChunkName: "app-layout" */'@pages/AppLayout'),
    children: [
      {
        name: 'dashboard',
        path: '/dashboard',
        redirect: ROUTES[ROUTE_NAMES.HOME].path,
      },
      {
        name: ROUTE_NAMES.HOME,
        path: ROUTES[ROUTE_NAMES.HOME].path,
        props: false,
        redirect: ROUTES[DEFAULT_HOME_ROUTE_NAME].path,
        components: {
          sidebar: () => import(/* webpackChunkName: "home-sidebar" */ '@sections/HomeSidebar'),
          content: () => import(/* webpackChunkName: "home-page" */ '@pages/Home'),
        },
        children: [
          {
            name: ROUTE_NAMES.ALL_SPACES,
            path: ROUTES[ROUTE_NAMES.ALL_SPACES].path,
            meta: {
              title: ROUTES[ROUTE_NAMES.ALL_SPACES].title,
            },
            props: false,
            component: () => import('@sections/AllSpaces'),
          },
          {
            name: ROUTE_NAMES.STARRED,
            path: ROUTES[ROUTE_NAMES.STARRED].path,
            meta: {
              title: ROUTES[ROUTE_NAMES.STARRED].title,
            },
            redirect: ROUTES[ROUTE_NAMES.STARRED_SPACES].path,
            props: false,
          },
          {
            name: ROUTE_NAMES.STARRED_SPACES,
            path: ROUTES[ROUTE_NAMES.STARRED_SPACES].path,
            meta: {
              title: ROUTES[ROUTE_NAMES.STARRED_SPACES].title,
            },
            props: false,
            component: () => import('@sections/StarredSpaces'),
          },
          {
            name: ROUTE_NAMES.STARRED_PAGES,
            path: ROUTES[ROUTE_NAMES.STARRED_PAGES].path,
            meta: {
              title: ROUTES[ROUTE_NAMES.STARRED_PAGES].title,
            },
            props: false,
            component: () => import('@sections/StarredPages'),
          },
          {
            path: '/home/categories',
            redirect: ROUTES[ROUTE_NAMES.ALL_SPACES].path,
          },
          {
            name: ROUTE_NAMES.CATEGORY,
            path: ROUTES[ROUTE_NAMES.CATEGORY].path,
            meta: {
              title: ROUTES[ROUTE_NAMES.CATEGORY].title,
            },
            props: true,
            component: () => import('@sections/SpaceCategory'),
            beforeEnter(to, from, next) {
              if (!store.getters['features/canUseSpaceCategories']) {
                next({ name: ROUTE_NAMES.ALL_SPACES });
                return;
              }

              next();
            },
          },
        ],
      },
      {
        name: ROUTE_NAMES.TAGS,
        path: ROUTES[ROUTE_NAMES.TAGS].path,
        meta: {
          title: ROUTES[ROUTE_NAMES.TAGS].title,
        },
        props: { sidebar: false, content: true },
        components: {
          sidebar: () => import(/* webpackChunkName: "home-sidebar" */ '@sections/HomeSidebar'),
          content: () => import(/* webpackChunkName: "tags-management" */ '@sections/TagsManagement'),
        },
        beforeEnter(to, from, next) {
          if (!store.getters['session/canAccessTagsRoute']) {
            next({ name: ROUTE_NAMES.HOME });
            return;
          }

          next();
        },
      },
      {
        name: ROUTE_NAMES.REQUIRED_READING,
        path: ROUTES[ROUTE_NAMES.REQUIRED_READING].path,
        meta: {
          title: ROUTES[ROUTE_NAMES.REQUIRED_READING].title,
        },
        props: { content: true },
        components: {
          content: () => import(/* webpackChunkName: "required-reading-page" */'@pages/RequiredReading'),
        },
        beforeEnter(to, from, next) {
          if (!store.getters['features/canUseRequiredReading']) {
            next({ name: ROUTE_NAMES.HOME });
            return;
          }

          next();
        },
      },
      {
        name: ROUTE_NAMES.LOG_OUT,
        path: ROUTES[ROUTE_NAMES.LOG_OUT].path,
        component: () => logout(),
      },
      {
        name: ROUTE_NAMES.PEOPLE,
        path: ROUTES[ROUTE_NAMES.PEOPLE].path,
        meta: {
          title: ROUTES[ROUTE_NAMES.PEOPLE].title,
        },
        props: false,
        redirect: ROUTES[ROUTE_NAMES.USERS].path,
        components: {
          content: () => import(/* webpackChunkName: "people-page" */ '@pages/People'),
          sidebar: () => import(/* webpackChunkName: "people-page" */ '@sections/PeopleSidebar'),
        },
        beforeEnter(to, from, next) {
          if (!store.getters['session/canManageUsers'] || !store.getters['features/canManageUsers']) {
            return next({ name: ROUTE_NAMES.HOME });
          }

          return next();
        },
        children: [
          {
            name: ROUTE_NAMES.USERS,
            path: ROUTES[ROUTE_NAMES.USERS].path,
            meta: {
              title: ROUTES[ROUTE_NAMES.USERS].title,
            },
            props: false,
            component: () => import('@sections/AllUsers'),
          },
          {
            name: ROUTE_NAMES.COMPANIES,
            path: ROUTES[ROUTE_NAMES.COMPANIES].path,
            meta: {
              title: ROUTES[ROUTE_NAMES.COMPANIES].title,
            },
            props: false,
            redirect: ROUTES[ROUTE_NAMES.USERS].path,
          },
          {
            name: ROUTE_NAMES.COMPANY,
            path: ROUTES[ROUTE_NAMES.COMPANY].path,
            meta: {
              title: ROUTES[ROUTE_NAMES.COMPANY].title,
            },
            props: true,
            component: () => import('@sections/Company'),
          },
        ],
      },
      {
        name: ROUTE_NAMES.SEARCH,
        path: ROUTES[ROUTE_NAMES.SEARCH].path,
        meta: {
          title: ROUTES[ROUTE_NAMES.SEARCH].title,
        },
        props: { content: true },
        components: {
          content: () => import(/* webpackChunkName: "search-page" */ '@pages/Search'),
        },
      },
    ],
  },
];
