import spacesService from '@/api/spacesService';

export const FEATURE_KEYS = Object.freeze({
  UNLIMITED_PAGES: 'unlimited-pages',
  UNLIMITED_USERS: 'unlimited-users',
  UNLIMITED_CUSTOM_PAGE_TEMPLATES: 'unlimited-custom-page-templates',
  UNLIMITED_PUBLIC_PAGE_SHARING: 'unlimited-public-page-sharing',
  PAGE_PRIVACY: 'page-privacy',
  REQUIRED_READING_REPORTING: 'required-reading-reporting',
  SPACE_PDF_EXPORT: 'space-pdf-export',
  GRANULAR_SPACE_PERMISSIONS: 'granular-space-permissions',
});

export const FEATURES = Object.freeze({
  [FEATURE_KEYS.UNLIMITED_PAGES]: {
    id: FEATURE_KEYS.UNLIMITED_PAGES,
    name: 'Unlimited Pages',
    plans: [],
  },
  [FEATURE_KEYS.UNLIMITED_USERS]: {
    id: FEATURE_KEYS.UNLIMITED_USERS,
    name: 'Unlimited Users',
  },
  [FEATURE_KEYS.UNLIMITED_CUSTOM_PAGE_TEMPLATES]: {
    id: FEATURE_KEYS.UNLIMITED_CUSTOM_PAGE_TEMPLATES,
    name: 'Unlimited Custom Page Templates',
  },
  [FEATURE_KEYS.UNLIMITED_PUBLIC_PAGE_SHARING]: {
    id: FEATURE_KEYS.UNLIMITED_PUBLIC_PAGE_SHARING,
    name: 'Unlimited Page Sharing',
  },
  [FEATURE_KEYS.PAGE_PRIVACY]: {
    id: FEATURE_KEYS.PAGE_PRIVACY,
    name: 'Page Privacy',
  },
  [FEATURE_KEYS.REQUIRED_READING_REPORTING]: {
    id: FEATURE_KEYS.REQUIRED_READING_REPORTING,
    name: 'Required Reading Reporting',
  },
  [FEATURE_KEYS.SPACE_PDF_EXPORT]: {
    id: FEATURE_KEYS.SPACE_PDF_EXPORT,
    name: 'Space PDF Export',
  },
  [FEATURE_KEYS.GRANULAR_SPACE_PERMISSIONS]: {
    id: FEATURE_KEYS.GRANULAR_SPACE_PERMISSIONS,
    name: 'Granular Space Permissions',
  },
});

export default {
  namespaced: true,
  getters: {
    // Note: we explictly don't want to factor the user limit into this. This getter should
    // act purely as a flag for whether or not any form of user management is enabled on this
    // installation
    canManageUsers: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['installation/get']) {
        return false;
      }

      return !rootGetters['installation/isOnAddOnPlan'];
    },

    hasReachedPageLimit: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['installationStats/get']) {
        return true;
      }

      if (!rootGetters['installation/isOnFreeAddOnPlan'] || rootGetters['installation/isOnStarterPlan']) {
        return false;
      }

      const maxPages = parseInt(rootGetters['installationStats/maxPages'], 10);
      const pageCount = parseInt(rootGetters['installationStats/pageCount'], 10);

      if (Number.isNaN(maxPages) || Number.isNaN(pageCount)) {
        return true;
      }

      return (
        maxPages > 0
        && pageCount >= maxPages
      );
    },

    hasReachedPageTemplateLimit: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['installationStats/get']) {
        return true;
      }

      if (!rootGetters['installation/isOnFreeAddOnPlan']) {
        return false;
      }

      const maxTemplates = parseInt(rootGetters['installationStats/maxTemplates'], 10);
      const templateCount = parseInt(rootGetters['installationStats/templateCount'], 10);

      if (Number.isNaN(maxTemplates) || Number.isNaN(templateCount)) {
        return true;
      }

      return (
        maxTemplates > 0
        && templateCount >= maxTemplates
      );
    },

    hasReachedPubliclySharedPageLimit: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['installationStats/get']) {
        return true;
      }

      if (!rootGetters['installation/isOnFreeAddOnPlan']) {
        return false;
      }

      const maxPageShares = parseInt(rootGetters['installationStats/maxPageShares'], 10);
      const pageShareCount = parseInt(rootGetters['installationStats/pageShareCount'], 10);

      if (Number.isNaN(maxPageShares) || Number.isNaN(pageShareCount)) {
        return true;
      }

      return (
        maxPageShares > 0
        && pageShareCount >= maxPageShares
      );
    },

    hasReachedUserLimit: (state, getters, rootState, rootGetters) => (
      rootGetters['installation/isOnStarterPlan']
        && rootGetters['users/activeUsersCount'] >= rootGetters['installation/planUsersCount']
    ),

    canCreatePage: (state, getters) => !getters.hasReachedPageLimit,

    canCreatePageTemplates: (state, getters) => !getters.hasReachedPageTemplateLimit,

    canExportSpaceAsPDF: (state, getters, rootState, rootGetters) => !rootGetters['installation/isOnFreeAddOnPlan'],

    canPubliclySharePages: (state, getters) => !getters.hasReachedPubliclySharedPageLimit,

    // Note: no current restriction on Required Reading feature
    canUseRequiredReading: () => true,

    canUseTemplates: (state, getters, rootState, rootGetters) => {
      if (rootGetters['installation/isOnPaidAddOnPlan']) {
        return !rootGetters['session/isClient'];
      }

      return true;
    },

    canUseTags: (state, getters, rootState, rootGetters) => {
      if (rootGetters['installation/isOnPaidAddOnPlan']) {
        return !rootGetters['session/isClient'];
      }

      return true;
    },

    canUseSpaceCategories: (state, getters, rootState, rootGetters) => {
      if (rootGetters['installation/isOnPaidAddOnPlan']) {
        return !rootGetters['session/isClient'];
      }

      return true;
    },

    canUseRequiredReadingReporting: (state, getters, rootState, rootGetters) => !(
      rootGetters['installation/isOnFreeAddOnPlan']
    ),

    canUsePagePrivacy: (state, getters, rootState, rootGetters) => !(
      rootGetters['installation/isOnStarterPlan']
        || rootGetters['installation/isOnFreeAddOnPlan']
    ),

    // Note: no current restriction on 'Space Manager' role
    canUseSpaceManagerRole: () => true,

    canUseSpaceEditorRole: (state, getters, rootState, rootGetters) => !(
      rootGetters['installation/isOnStarterPlan']
        || rootGetters['installation/isOnFreeAddOnPlan']
    ),

    canUseSpaceReaderRole: (state, getters, rootState, rootGetters) => !(
      rootGetters['installation/isOnStarterPlan']
        || rootGetters['installation/isOnFreeAddOnPlan']
    ),

    canUseGranularSpacePermissions: (state, getters) => (
      getters.canUseSpaceManagerRole
        && getters.canUseSpaceEditorRole
        && getters.canUseSpaceReaderRole
    ),

    restrictedFeatures: (state, getters, rootState, rootGetters) => {
      if (rootGetters['installation/isOnFreeAddOnPlan']) {
        return [
          FEATURES[FEATURE_KEYS.GRANULAR_SPACE_PERMISSIONS],
          FEATURES[FEATURE_KEYS.SPACE_PDF_EXPORT],
          FEATURES[FEATURE_KEYS.PAGE_PRIVACY],
          FEATURES[FEATURE_KEYS.REQUIRED_READING_REPORTING],
        ];
      }

      if (rootGetters['installation/isOnStarterPlan']) {
        return [
          FEATURES[FEATURE_KEYS.GRANULAR_SPACE_PERMISSIONS],
          FEATURES[FEATURE_KEYS.SPACE_PDF_EXPORT],
          FEATURES[FEATURE_KEYS.PAGE_PRIVACY],
          FEATURES[FEATURE_KEYS.REQUIRED_READING_REPORTING],
        ];
      }

      return [];
    },

    limitedFeatures: (state, getters, rootState, rootGetters) => {
      if (rootGetters['installation/isOnFreeAddOnPlan']) {
        return [
          FEATURES[FEATURE_KEYS.UNLIMITED_PAGES],
          FEATURES[FEATURE_KEYS.UNLIMITED_CUSTOM_PAGE_TEMPLATES],
          FEATURES[FEATURE_KEYS.UNLIMITED_PUBLIC_PAGE_SHARING],
        ];
      }

      if (rootGetters['installation/isOnStarterPlan']) {
        return [
          FEATURES[FEATURE_KEYS.UNLIMITED_PAGES],
          FEATURES[FEATURE_KEYS.UNLIMITED_USERS],
          FEATURES[FEATURE_KEYS.UNLIMITED_CUSTOM_PAGE_TEMPLATES],
          FEATURES[FEATURE_KEYS.UNLIMITED_PUBLIC_PAGE_SHARING],
        ];
      }

      return [];
    },
  },
  actions: {
    check({ rootGetters }) {
      // Anytime the socket is connected we sync the latest stats making this API call redundant
      if (rootGetters['socket/isConnected']) {
        return Promise.resolve();
      }

      return spacesService.getInstallationStats();
    },

    async refreshSubscriptionData() {
      return Promise.all([
        spacesService.getSession(),
        spacesService.getInstallationStats(),
      ]);
    },

    checkIfPassesPageLimitGuard({ getters, dispatch }) {
      if (!getters.hasReachedPageLimit) {
        return true;
      }
      dispatch('modals/closeModal', null, { root: true });
      dispatch('triggerUpgradeModal', { feature: FEATURE_KEYS.UNLIMITED_PAGES });
      return false;
    },

    triggerUpgradeModal({ dispatch, rootGetters }, { feature }) {
      if (!Object.values(FEATURE_KEYS).includes(feature)) {
        throw new Error('Parameter `feature` is missing or invalid');
      }

      if (rootGetters['installation/isOnFreeAddOnPlan']) {
        dispatch('modals/openModal', {
          activeModal: 'free-addon-limit-reached',
          modalProps: {
            shouldFocusFirstElement: false,
          },
        }, { root: true });
        return;
      }

      if (rootGetters['installation/isOnStarterPlan']) {
        if (feature === FEATURE_KEYS.UNLIMITED_PAGES) {
          dispatch('modals/openModal', { activeModal: 'page-limit-reached' }, { root: true });
          return;
        }

        if (feature === FEATURE_KEYS.UNLIMITED_USERS) {
          dispatch('modals/openModal', { activeModal: 'user-limit-reached' }, { root: true });
        }
      }
    },
  },
};
