/* eslint-disable no-unused-vars */
import store from '@/store';
import spacesService from '@/api/spacesService';
import initFullstory from '@/utils/analytics/fullstory';
import { initPendo } from '@/utils/analytics/pendo';
import fullName from '@/utils/filters/fullName';
import {
  setUserIdInGoogleAnalytics,
  setInstallationIdInGoogleAnalytics,
  setIsAdminInAnalytics,
  setPlanUsers,
  setSignupProduct,
  setIsFreeTrialInAnalytics,
} from '@/utils/analytics/google';
import asyncTryCatchHelper from '@/utils/helpers/asyncTryCatchHelper';

async function fetchPermissionsForSpace(spaceCode) {
  await spacesService.getActiveSpacePermission(spaceCode);
  spacesService.getSpacePermissions(spaceCode);
}

async function fetchSpaceChangeDataForRoute(to, from, appStarting) {
  spacesService.getSpace(to.params.spaceCode);
  spacesService.getSpacePages(to.params.spaceCode);
  spacesService.getTrashedSpacePages(to.params.spaceCode);
  spacesService.getSpaceFollowingState(to.params.spaceCode);
  asyncTryCatchHelper(async () => {
    await fetchPermissionsForSpace(to.params.spaceCode);
  });
}

const ROUTE_NAMES = Object.freeze({
  ALL_SPACES: 'all-spaces',
  APPS_SDK: 'settingsAppsSDK',
  CATEGORY: 'category',
  COMPANIES: 'companies',
  COMPANY: 'company',
  HOME: 'home',
  LOG_OUT: 'logout',
  NOT_FOUND: 'not-found',
  ONBOARDING_FIRST_SPACE: 'onboarding.create-space',
  ONBOARDING_INVITE: 'onboarding.invite',
  ONBOARDING_SELECT_SPACE: 'onboarding.select-space',
  ONBOARDING: 'onboarding',
  PEOPLE: 'people',
  REQUIRED_READING: 'required-reading',
  SEARCH: 'search',
  SETTINGS_API_KEYS: 'settingsApiKeys',
  SETTINGS_PERSONAL: 'settingsPersonal',
  SETTINGS_SUBSCRIPTION: 'settingsSubscription',
  SETTINGS: 'settings',
  SPACE_ATTACHMENTS: 'spaceAttachments',
  SPACE_PAGE: 'spacePage',
  SPACE_SETTINGS: 'spaceSettings',
  SPACE_SETTINGS_OPTIONS: 'spaceOptions',
  SPACE_SETTINGS_PERMISSIONS: 'spacePermissions',
  SPACE_SETTINGS_ORGANIZE: 'spaceOrganize',
  SPACE_SETTINGS_TRASH: 'spaceTrash',
  SPACE_SETTINGS_INSIGHTS: 'spaceInsights',
  SPACE: 'space',
  STARRED: 'starred',
  STARRED_SPACES: 'starred-spaces',
  STARRED_PAGES: 'starred-pages',
  TAGS: 'tagsManagement',
  TEMPLATES: 'templatesManagement',
  TEMPLATES_CREATE: 'pageTemplate.create',
  TEMPLATES_EDIT: 'pageTemplate.edit',
  TEMPLATES_GALLERY: 'templatesGallery',
  TEMPLATES_CUSTOM_TEMPLATE: 'customTemplate',
  TEMPLATES_GLOBAL_TEMPLATE: 'globalTemplate',
  USERS: 'users',
  WEBHOOK: 'webhook',
  WEBHOOKS: 'webhooks',
});

const ROUTES = Object.freeze({
  [ROUTE_NAMES.HOME]: {
    path: '/home',
    title: 'Home',
    prefetchRouteData() {
      spacesService.getCategorisedSpaces();
      spacesService.getStarredSpaces();
      spacesService.getUsers();
    },
  },
  [ROUTE_NAMES.ALL_SPACES]: {
    path: '/home/all',
    title: 'All spaces',
    prefetchRouteData() {

    },
  },
  [ROUTE_NAMES.STARRED]: {
    path: '/home/starred',
    title: 'Starred spaces',
    prefetchRouteData() {

    },
  },
  [ROUTE_NAMES.STARRED_SPACES]: {
    path: '/home/starred-spaces',
    title: 'Starred spaces',
    prefetchRouteData() {

    },
    fetchData() {
      spacesService.getStarredSpaces();
    },
  },
  [ROUTE_NAMES.STARRED_PAGES]: {
    path: '/home/starred-pages',
    title: 'Starred pages',
    prefetchRouteData() {
      spacesService.getStarredPages();
    },
    fetchData() {
      spacesService.getStarredPages();
    },
  },
  [ROUTE_NAMES.CATEGORY]: {
    path: '/home/categories/:id',
    title: '',
    prefetchRouteData() {

    },
  },
  [ROUTE_NAMES.TAGS]: {
    path: '/home/tags',
    title: 'Tags',
    prefetchRouteData() {

    },
  },
  [ROUTE_NAMES.REQUIRED_READING]: {
    path: '/required-reading',
    title: 'Required Reading',
    async prefetchRouteData() {
      await spacesService.getSession();
      spacesService.getSessionSpacePermissions();
      spacesService.getRequiredReading();
    },
  },
  [ROUTE_NAMES.PEOPLE]: {
    path: '/people',
    title: 'People',
    prefetchRouteData() {
      spacesService.getUsers();
      spacesService.getCompanies();
    },
  },
  [ROUTE_NAMES.COMPANY]: {
    path: '/people/companies/:id',
    title: 'Company',
  },
  [ROUTE_NAMES.USERS]: {
    path: '/people/users',
    title: 'All users',
  },
  [ROUTE_NAMES.COMPANIES]: {
    path: '/people/companies',
    title: 'All companies',
  },
  [ROUTE_NAMES.LOG_OUT]: {
    path: '/logout',
    title: null,
  },
  [ROUTE_NAMES.SEARCH]: {
    path: '/search',
    title: 'Search',
  },
  [ROUTE_NAMES.ONBOARDING]: {
    path: '/welcome',
    title: null,
  },
  [ROUTE_NAMES.ONBOARDING_INVITE]: {
    path: '/welcome/invite',
    title: null,
  },
  [ROUTE_NAMES.ONBOARDING_SELECT_SPACE]: {
    path: '/welcome/select-space',
    title: null,
  },
  [ROUTE_NAMES.ONBOARDING_FIRST_SPACE]: {
    path: '/welcome/first-space',
    title: null,
  },
  [ROUTE_NAMES.SETTINGS]: {
    path: '/settings',
    title: 'Settings',
  },
  [ROUTE_NAMES.SETTINGS_PERSONAL]: {
    path: '/settings/personal',
    title: 'Personal',
  },
  [ROUTE_NAMES.SETTINGS_SUBSCRIPTION]: {
    path: '/settings/subscription',
    title: 'Subscription',
  },
  [ROUTE_NAMES.SETTINGS_API_KEYS]: {
    path: '/settings/apikeys',
    title: 'API Keys',
    prefetchRouteData() {
      spacesService.getApiKeys();
    },
  },
  [ROUTE_NAMES.TEMPLATES]: {
    path: '/templates',
    title: 'Page Templates',
    prefetchRouteData() {
      spacesService.getPageTemplates();
      spacesService.getPageTemplatesGallery();
    },
  },
  [ROUTE_NAMES.TEMPLATES_CREATE]: {
    path: '/templates/create',
    title: 'Templates - Create',
    prefetchRouteData() {
      spacesService.getPageTemplates();
      spacesService.getPageTemplatesGallery();
    },
  },
  [ROUTE_NAMES.TEMPLATES_EDIT]: {
    path: '/templates/:templateId(\\d+)/edit',
    title: 'Templates - Edit',
    prefetchRouteData() {
      spacesService.getPageTemplates();
      spacesService.getPageTemplatesGallery();
    },
  },
  [ROUTE_NAMES.TEMPLATES_GALLERY]: {
    path: '/templates/gallery',
    title: 'Templates - Gallery',
    prefetchRouteData() {
      spacesService.getPageTemplatesGallery();
      spacesService.getPageTemplates();
    },
  },
  [ROUTE_NAMES.TEMPLATES_CUSTOM_TEMPLATE]: {
    path: '/templates/custom/:templateId(\\d+)/preview',
    title: 'Individual Custom Template',
    prefetchRouteData() {
      spacesService.getPageTemplates();
      spacesService.getPageTemplatesGallery();
    },
  },
  [ROUTE_NAMES.TEMPLATES_GLOBAL_TEMPLATE]: {
    path: '/templates/global/:templateId(\\d+)/preview',
    title: 'Individual Global Template',
    prefetchRouteData() {
      spacesService.getPageTemplates();
      spacesService.getPageTemplatesGallery();
    },
  },
  [ROUTE_NAMES.SPACE_PAGE]: {
    path: '/:spaceCode/page/:pageCode/:action?',
    title: 'Space Page',
    prefetchRouteData(route) {
      const pageId = route.params.pageCode.split('-')[0];
      spacesService.getActiveSpacePermission(route.params.spaceCode);
      spacesService.getPage(route.params.spaceCode, pageId);
      spacesService.getExternalApps();
      spacesService.getLaunchpadInfo();
      spacesService.getApiKeys();
      spacesService.getSpace(route.params.spaceCode);
    },
    fetchData(to, from, appStarting) {
      const pageCode = to.params.pageCode === 'home' ? 'home' : to.params.pageCode.split('-')[0];
      const spaceCode = to.params.spaceCode;

      // do not do anything for a hash within a page only
      if (to.params.pageCode === from.params.pageCode && to.hash && !appStarting) {
        return;
      }

      // if simply going to edit mode from read mode
      if (
        to.params.action === 'edit'
        && from.params.pageCode === to.params.pageCode
        && !appStarting
      ) {
        // get page permissions
        spacesService.getPagePermissions(spaceCode, pageCode === 'home' ? store.getters['navigation/activePageId'] : pageCode);

        // refetch pages from an add page operation
        spacesService.getSpacePages(spaceCode);
        return;
      }

      // don't do anyhing if going back to the page from the space
      if (from.params.action === 'edit' && !to.params.action) {
        return;
      }
      // What can happen here:
      // 1. Direct Space Home Navigation
      // 2. Direct Space Home Edit Navigation
      // 3. Direct Space Page Navigation
      // 4. Direct Space Page Edit Navigation
      // 5. Change Page Navigation
      // 6. Change Space Navigation
      // 7. Edit Page Navigaton
      // 8. Add Page Navigation - (no fetch calls should be needed here really, as the 201 on Add Page should be enough)

      spacesService.getActiveSpacePermission(spaceCode);

      // We need to tear down the editor any time we get a page
      // This is to ensure OT reinstantiates at the correct mongo document
      // but we only need to do this when changing pages
      // there is a scenario were changing the privacy state will fire
      // off requests for the page again within the editor,
      // in this case we do not want to flush the editor
      if (to.params.action !== 'edit') {
        store.commit('navigation/setIsFlushingEditorOnPageCodeChange', true);
      }
      // fetch the page regardless
      spacesService.getPage(spaceCode, pageCode).then((response) => {
        spacesService.getComments(spaceCode, response.data.page.id);
        spacesService.getInlineComments(spaceCode, response.data.page.id);
        spacesService.getPagePermissions(spaceCode, response.data.page.id);
        spacesService.getPageSharingStatus(spaceCode, response.data.page.id);
        spacesService.getPageFollowingState(spaceCode, response.data.page.id);
        spacesService.getCollaborators(spaceCode, response.data.page.id);
        spacesService.getRequiredReadingStatus(spaceCode, response.data.page.id);
      }).finally(() => {
        // ensure flushing status always gets reset
        store.commit('navigation/setIsFlushingEditorOnPageCodeChange', false);
      });

      // only get space pages if changing space or if app is starting
      if (from.params.spaceCode !== spaceCode) {
        fetchSpaceChangeDataForRoute(to, from, appStarting);
      }
    },
  },
  [ROUTE_NAMES.SPACE_SETTINGS_OPTIONS]: {
    path: '/:spaceCode/settings/options',
    title: 'Space Settings',
    prefetchRouteData(to, from, appStarting) {
      if (appStarting) {
        fetchSpaceChangeDataForRoute(to, from, appStarting);
      }
    },
    fetchData(to, from, appStarting) {
      if (appStarting) {
        fetchSpaceChangeDataForRoute(to, from, appStarting);
      }
    },
  },
  [ROUTE_NAMES.SPACE_SETTINGS_ORGANIZE]: {
    path: '/:spaceCode/settings/organize',
    title: 'Organize',
    prefetchRouteData(to, from, appStarting) {
      if (appStarting) {
        fetchSpaceChangeDataForRoute(to, from, appStarting);
      }
    },
    fetchData(to, from, appStarting) {
      if (appStarting) {
        fetchSpaceChangeDataForRoute(to, from, appStarting);
      }
    },
  },
  [ROUTE_NAMES.SPACE_SETTINGS_PERMISSIONS]: {
    path: '/:spaceCode/settings/permissions',
    title: 'Space Permissions',
    fetchData(to, from, appStarting) {
      if (appStarting) {
        fetchSpaceChangeDataForRoute(to, from, appStarting);
      }
      spacesService.getSpacePermissions(to.params.spaceCode);
      spacesService.getSpaceAccessRequests(to.params.spaceCode);
    },
  },
  [ROUTE_NAMES.SPACE_SETTINGS_TRASH]: {
    path: '/:spaceCode/settings/trash',
    title: 'Trash Can',
    fetchData(to, from, appStarting) {
      spacesService.getTrashedSpacePages(to.params.spaceCode);
      if (appStarting) {
        fetchSpaceChangeDataForRoute(to, from, appStarting);
      }
    },
  },
  [ROUTE_NAMES.SPACE_SETTINGS_INSIGHTS]: {
    path: '/:spaceCode/settings/insights',
    title: 'Insights',
    fetchData(to, from, appStarting) {
      // Insights doesn't use vuex currently all data is in the component itself
      if (appStarting) {
        fetchSpaceChangeDataForRoute(to, from, appStarting);
      }
    },
  },
  [ROUTE_NAMES.SPACE_ATTACHMENTS]: {
    path: '/:spaceCode/attachments',
    title: 'Attachments',
    prefetchRouteData(route) {
      spacesService.getSpacePermissions(route.params.spaceCode);
      spacesService.getSpace(route.params.spaceCode);
      spacesService.getSpaceAttachments(route.params.spaceCode);
    },
    fetchData(to, from, appStarting) {
      spacesService.getSpaceAttachments(to.params.spaceCode);
      if (appStarting) {
        fetchSpaceChangeDataForRoute(to, from, appStarting);
      }
    },
  },
  [ROUTE_NAMES.WEBHOOK]: {
    path: '/settings/webhooks/:id',
    title: 'Webhook',
  },
  [ROUTE_NAMES.WEBHOOKS]: {
    path: '/settings/webhooks',
    title: 'Webhooks',
  },
  [ROUTE_NAMES.APPS_SDK]: {
    path: '/settings/apps-sdk',
    title: 'Apps SDK',
  },
});

// This is the core code we used to call within App.js
// This will only ever be invoked when the app is starting
// but it will fire after the route fetches
function fetchGlobalData() {
  spacesService.getSessionSpacePermissions();
  spacesService.getCategorisedSpaces();
  spacesService.getStarredSpaces();
  spacesService.getCompanies();
  const launchpad = spacesService.getLaunchpadInfo();
  spacesService.getRequiredReading();
  spacesService.getExternalApps();
  spacesService.getEmojiData();
  spacesService.getInstallationStats();
  spacesService.getNotifications();
  spacesService.getUsers();
  spacesService.getPageLinks();
  if (store.getters['features/canUseTemplates']) {
    spacesService.getPageTemplates();
    spacesService.getPageTemplatesGallery();
  }
  if (store.getters['features/canUseTags']) {
    spacesService.getTags();
  }

  Promise.all([launchpad]).then(() => {
    // init pendo and fullstory
    initPendo();

    initFullstory(
      store.getters['session/userId'],
      {
        displayName: fullName(store.getters['session/user']),
        email: store.getters['session/user'].email,
        userId: store.getters['session/userId'],
        region: store.getters['installation/region'],
        installationId: store.getters['installation/id'],
        installationName: store.getters['installation/name'],
        siteOwner: store.getters['installation/appOwnerId'],
        companyName: store.getters['session/companyName'],
        createdAt: store.getters['installation/createdAt'],
        isImpersonating: window.shouldUseFullStory,
        product: 'Spaces',
      });

    setIsFreeTrialInAnalytics(store.getters['installation/hasActiveTrial']);
    setPlanUsers(store.getters['installation/planUsersCount']);
    setUserIdInGoogleAnalytics(store.getters['session/userId']);
    setInstallationIdInGoogleAnalytics(store.getters['installation/id']);
    setIsAdminInAnalytics(store.getters['session/isAdmin']);
    setSignupProduct(store.getters['installation/signupProduct']);
  });
}

function fetchDataForRoute(to, from, appStarting) {
  // clean the promise map
  if (!appStarting) {
    spacesService.clean();
  }

  if (ROUTES[to.name] && (typeof ROUTES[to.name].fetchData) === 'function') {
    // Prioritize route data first
    ROUTES[to.name].fetchData(to, from, appStarting);
  }

  // If app is starting make the the app level calls
  // after the route data fetches
  if (appStarting) {
    fetchGlobalData();
  }
}

export {
  ROUTES,
  ROUTE_NAMES,
  fetchDataForRoute,
};
